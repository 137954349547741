import React from 'react';
import '../../../css/stratcards.css';
import cards from '../../../data/card-data.json';

import Card from './Card';

const Playcards = () => {

  return (
    <main>
      <div className="mainPages stratCardsMain">
        <h1>Play Cards</h1>
        <ul className="todolist">
          <li>Map Filter Auflistung einbauen</li>
          <li>Kartenstapel / Fächer erstellen</li>
          <li>Map Images erstellen</li>
        </ul>
        
        <div className="contentWrapper">
          <div className="filterArea">
            <span>FILTER:</span>
            <button>Ancient</button>
            <button>Tuscan</button>
          </div>

          <div className="card-wrap">
            {
              Object.keys(cards).map(key => (
                <Card
                  key={key}
                  index={key}
                  card={cards[key]}
                />
              ))
            }
          </div>

        </div>
      </div>

    </main>
  );
};

export default Playcards;