import { Link } from "react-router-dom";

const Dev = () => {
  return (
    <main>
      <div className="mainPages">
        <h2 className="mainTitle textCenter">Web Development</h2>
        <div className="gallery">
          <Link to="playcards">
            <div className="galleryEntry">
              <div className="exampleImage"></div>
              <div className="exampleText">
                <h3>Play Cards</h3>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
              </div>
            </div>
          </Link>
          <div className="galleryEntry">
            <div className="exampleImage"></div>
            <div className="exampleText">
              <h3>Organizr App</h3>
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
            </div>    
          </div>
        </div>
      </div>
    </main>
  );
}

export default Dev;
