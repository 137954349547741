const NoMatch = () => {
  return (
    <main>
      <div className="mainPages">
        <div className="center-content">
          <p>Page not Found</p>
        </div>
      </div>
    </main>
  );
}

export default NoMatch;
