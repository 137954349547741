import { useState } from 'react';
import { Routes, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Header from './layout/Header';
import Footer from './layout/Footer';
import Login from './pages/Login';
import PrivateRoutes from './config/PrivateRoutes'
import Home from './pages/Home';
import Dev from './pages/Dev';
import Playcards from './pages/cards/Playcards';
import Gfx from './pages/Gfx';
import Other from './pages/Other';
import NoMatch from './pages/Nomatch';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(null);

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  });

  return (
    <div>
      <Header loggedIn={loggedIn} />
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="dev">
              <Route index={true} element={<Dev />} />
              <Route path="playcards" element={<Playcards />} />
            </Route>
            <Route path="gfx" element={<Gfx />} />
            <Route path="other" element={<Other />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
          <Route element={<Login />} path="/login" />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      <Footer />
    </div>
  );
}

export default App;