import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "../../css/login.css";
import Logo from "../../images/logo-clean.png";
import loginIcon from "../../images/login.svg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // trigger loading animation
      return;
    }
    if (user) navigate("/home");
  }, [user, loading, navigate]);

  return (
    <main>
      <div className="mainLogin">
        <div className="loginArea">
          <div>
            <span>L</span><img src={Logo} alt="Logo" /><span>gin</span>
          </div>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail Address"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button onClick={() => logInWithEmailAndPassword(email, password)}><img src={loginIcon} alt="Login" /></button>
        </div>
      </div>
    </main>
  );
}

export default Login;