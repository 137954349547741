const Other = () => {
  return (
    <main>
      <div className="mainPages">
        <h2 className="mainTitle textCenter">Other</h2>
        <div className="gallery">
          <div className="galleryEntry">
            <div className="exampleImage"></div>
            <div className="exampleText">
              <h3>Title 1</h3>
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
            </div>
          </div>
          <div className="galleryEntry">
            <div className="exampleImage"></div>
            <div className="exampleText">
              <h3>Title 2</h3>
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Other;