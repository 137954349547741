import React, { useState } from 'react';
import '../../../css/stratcards.css';

const Card = (props) => {

  const [flipped, setFlipped] = useState(false);
  function handleClick(){
    setFlipped(flipped => !flipped);
  }
  let toggleClassCheck = flipped ? " flipped": "";

  const detail = props.card;

  let cardName = detail.name.replaceAll(' ', '-');

  return (
    <div className="card-scene">
      <div className={`card${toggleClassCheck}`} onClick={handleClick}>

        <div className={detail.side === "t" ? "card-face front t" : "card-face front ct"}>
          <div className={detail.side === "t" ? "side-mark t" : "side-mark ct"}>
            <span>{detail.map} {detail.side} Side</span>
          </div>
          <div className="content">
            <div className="title">
              <p>{detail.map}</p>
              <h3>{detail.name}</h3>
              <p>{detail.players} {detail.players !== "1" ? "Players" : "Player"}</p>
            </div>
            <div className="utility">
              <h4>Utility</h4>
              <div className="util-contents">
                {
                  detail.utility.smoke !== "0" 
                    ? <div className="icons">
                        <span>{detail.utility.smoke} x</span><span className="smoke"></span>
                      </div> 
                    : ""
                }
                {
                  detail.utility.flash !== "0" 
                    ? <div className="icons">
                        <span>{detail.utility.flash} x</span><span className="flash"></span>
                      </div> 
                    : ""
                }
                {
                  detail.utility.molly !== "0" 
                    ? <div className="icons">
                        <span>{detail.utility.molly} x</span><span className="molly"></span>
                      </div> 
                    : ""
                }
                {
                  detail.utility.he !== "0" 
                    ? <div className="icons">
                        <span>{detail.utility.he} x</span><span className="he"></span>
                      </div> 
                    : ""
                }
              </div>
            </div>
            <div className="plan">
              <h4>Plan</h4>
              <ul>
                {detail.plan.split(';').map((i, key) => (
                  <li className="standardText" key={key}>{i}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className={detail.side === "t" ? "card-face back t" : "card-face back ct"}>
          <div className="content">
            <div className="map-overview">
              <h4>Map Overview</h4>
              <img alt="map radar" src={"/images/" + detail.map + "-" + cardName + ".svg"} />
            </div>
            <div className="details">
              <h4>Details</h4>
              <ul>
                {detail.details.split(';').map((i, key) => (
                  <li className="standardText" key={key}>{i}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className={detail.side === "t" ? "side-mark t" : "side-mark ct"}>
            <span>{detail.map} {detail.side} Side</span>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Card;