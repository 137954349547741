import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCyAqPBSpEqpZ0azMNymq3PIjZfHxUqXvM",
  authDomain: "kramcke-portfolio.firebaseapp.com",
  projectId: "kramcke-portfolio",
  storageBucket: "kramcke-portfolio.appspot.com",
  messagingSenderId: "35185831656",
  appId: "1:35185831656:web:c3d054c49772e27010a35d"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export { auth, db, logInWithEmailAndPassword, logout };