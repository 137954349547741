import "../../css/home.css";

const Home = () => {
  return (
    <main>
      <div className="mainPages">
        <h1 className="mainTitle textCenter">KR | HUB</h1>
        <div className="heroImage">Hero Images Dev</div>
        <div className="heroImage">Hero Images GFX</div>
        <div className="heroImage">Hero Images Sonstiges</div>
      </div>
    </main>
  );
}

export default Home;