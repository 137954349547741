import { Link } from "react-router-dom";
import Navigation from "../navigation/Navigation";
import Logo from "../../images/logo-clean.png";

const Header = props => {
  return (
    <header>
      <span>
        {
          props.loggedIn !== true 
            ? "" 
            : <Link to="/"><img className="logo" src={Logo} alt="Brand" /></Link>
        }
      </span>
      <span>
        <Navigation loggedIn={props.loggedIn} />
      </span>
    </header>
  );
}

export default Header;